.mapboxgl-popup{
  z-index: 2;
}
.mapboxgl-popup-content {
  position: relative;
  background: #fff;
  border-radius: 12px !important;
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%) !important;
  padding: 10px 10px 15px;
  pointer-events: auto;
  padding-bottom: 2rem !important;
}

.description {
  text-align: left;
}

.description span {
  font-weight: 900;
}

.description p {
  margin-bottom: 00.5rem;
}

.contanier {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;
  z-index: 1;
}

.col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.searchName {
  background-color: #fff;
  color: rgba(35, 55, 75, 0.9);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 12px;
  box-shadow: 0 3px 14px rgb(0 0 0 / 20%);
}

.searchName input {
  width: 80%;
  outline: none;
  background-color: transparent;
  border: none;
}

.chips {
  background-color: #fff;
  color: rgba(35, 55, 75, 0.9);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 900;
  font-size: 14px;
  box-shadow: 0 3px 14px rgb(0 0 0 / 20%);
  padding: 0px 45px;
}

.mapboxgl-ctrl-bottom-left {
  display: flex;
}

.mapboxgl-popup-close-button {
  font-size: 30px;
  padding: 0.5rem;
}


/*Loader style*/
.loader-line {
  width: 100%;
  z-index: 10;
  height: 3px;
  position: absolute;
  top: 0;
  overflow: hidden;
  background-color: #ddd;
  margin: auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: rgb(15, 161, 230);
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.logo {
  background-color: #fff;
  width: 75px;
  border-radius: 12px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 850px) {
.logo{
  position: fixed;
  bottom: 6%;
  left: 2%;
}
}